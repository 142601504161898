.testimonials {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
}
.testi-head {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.testi-head_left h2 {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 120.93% */
  letter-spacing: -1.29px;
}
.testi-head_left p {
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: -0.6px;
  width: 60%;
  padding: 20px 0;
}
.each-testi {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 347px;
}
.each-head {
  display: flex;
  gap: 20px;
  align-items: center;
}
.each-head img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.each-head_text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.each-head_text h4 {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 104% */
  letter-spacing: -0.75px;
}
.each-head_text h5 {
  color: #000;
  font-family: Gilroy-Medium;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 136.842% */
  letter-spacing: -0.57px;
}
.each-body p {
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.54px;
}
.slider-img-tes {
  padding: 70px 0;
}
.slider-img-tes swiper-container {
  height: 350px;
  /* position: relative; */
  /* border: 1px solid green; */
  padding-left: 20px;
}
.slider-img-tes swiper-container .each-testi{
  width: 100%;
}
.slider-img-tes swiper-container::part(button-prev) {
  /* border: 1px solid red; */
  position: absolute;
  bottom: -10px;
  /* top: 0; */
  /* left: 20%; */
}
.slider-img-tes swiper-container::part(button-next) {
  /* border: 1px solid purple; */
  position: absolute;
  bottom: -20% !important;
  left: 20%;
}


@media (max-width: 900px) {
    .testi-head{
        flex-direction: column-reverse;
    }
    .testi-head_right img{
        width: 100%;
    }
    .testi-head_left h2{
        font-size: 30px;
    }
    .testi-head_left p{
        width: 100%;
        font-size: 15px;
    }
    .each-head_text h4{
        font-size: 14px;
    }
    .each-head_text h5{
        font-size: 12px;
    }
    .each-body p{
        font-size: 13px;
    }
    .slider-img-tes swiper-container {
        height: 300px;
    }
    .each-testi{
        width: 260px;
    }
}