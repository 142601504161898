
.join-say{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 40px;
}
.join-say_head h2{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 120.93% */
    letter-spacing: -1.29px;
}
.join-say_body{
    display: flex;
    gap: 70px;
    justify-content: center;
}
.joinsay-comp{
    border-radius: 34px;
    border: 1px solid var(--Theme-2, #0D2D53);
    width: 45%;
    display: flex;
    gap: 25px;
    padding: 30px;
    flex-direction: column;
}
.joinsay-comp img{
    border-radius: 26px;
    height: 530px;
}
.joinsay-comp_head{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 20px ;
}
.joinsay-comp_head h3{
    /* color: var(--Theme-2, #0D2D53); */
    font-family: Gilroy-Bold;
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 100% */
    letter-spacing: -0.93px;
}
.joinsay-comp_head p{
    /* color: #555; */
    font-family: Gilroy-Medium;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.63px;
}
.joinsay-comp_head h5, .more-array h5{
    /* color: #000; */
    font-family: Gilroy-SemiBold;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 123.81% */
    letter-spacing: -0.63px;
    text-decoration-line: underline;
    cursor: pointer;
}

.more-array{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 20px;
}
.each-more h4{
    font-family: Gilroy-SemiBold;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.78px;
}
.each-more ul{
    padding: 10px;
}
.each-more ul li{
    font-family: Gilroy-Medium;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.69px;
}

@media (max-width: 900px) {
    .join-say_body{
        flex-direction: column;
        gap: 35px;
    }
    .join-say_head h2{
        font-size: 30px;
    }
    .joinsay-comp{
        width: 100%;
        padding: 20px 10px;
    }
    .joinsay-comp img{
        height: 276.651px;
    }
    .joinsay-comp_head h3{
        font-size: 20px;
    }
    .joinsay-comp_head p{
        font-size: 16px;
    }
    .joinsay-comp_head h5, .more-array h5{
        font-size: 16px;
    }
    .each-more h4{
        font-size: 17px;
    }
    .each-more ul li{
        font-size: 15px;
    }
}