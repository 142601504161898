.about-comp {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 65px 40px;
}
.about-comp_left {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}
.about-comp_left p {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 136.842% */
  letter-spacing: -0.57px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.about-comp_left p span {
  border-radius: 50px;
  background: var(--Theme, #07bef7);
  width: 94px;
  height: 2px;
}
.about-comp_right {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  /* border: 1px solid red; */
}
.about-comp_right h2 {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 111.628% */
  letter-spacing: -1.29px;
  width: 80%;
}
.about-comp_right h2 span {
  color: var(--Theme, #07bef7);
}
.about-comp_right p {
  width: 65%;
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 135% */
  letter-spacing: -0.6px;
}
.floating-right {
  position: absolute;
  right: 10px;
  bottom: 80px;
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
}
.floating-right p {
  color: #000;
  font-family: Gilroy-Bold;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 61.905% */
  letter-spacing: -1.26px;
}
.floating-right p span {
  color: #323232;
  font-family: Gilroy-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
  letter-spacing: -0.6px;
  /* border: 1px solid red; */
}

@media (max-width: 900px) {
  .about-comp {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }
  .about-comp_left {
    width: 100%;
    align-items: flex-start;
  }
  .about-comp_left img {
    display: none;
  }
  .about-comp_right {
    width: 100%;
  }
  .about-comp_right h2 {
    width: 100%;
    font-size: 30px;
  }
  .about-comp_right p {
    width: 100%;
    font-size: 15px;
  }
  .floating-right {
    position: relative;
    bottom: 0;
  }
  .floating-right p {
    font-size: 30px;
    width: 40%;
  }
  .floating-right p span {
    font-size: 15px;
    line-height: normal;
  }
}
