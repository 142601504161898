.input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.input input,
.input select {
  background: #cef1f3;
  color: #4f4f4f;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.54px;
  padding: 25px;
  width: 100%;
}
.small-input input,
.small-input select {
  background: #cef1f3;
  color: #4f4f4f;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.54px;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
}

.textarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.textarea textarea {
  background: #cef1f3;
  color: #4f4f4f;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.54px;
  padding: 25px;
  display: inline-flex;
  width: 100%;
}
.contact-right_btn {
  width: 100%;
  display: flex;
}
.input label {
  color: #494949;
  font-family: Gilroy-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 140.909% */
  letter-spacing: -0.66px;
}
.small-input label {
  color: #494949;
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 140.909% */
  letter-spacing: -0.66px;
}
.input select,
.small-input select {
  cursor: pointer;
}
.err-p {
  color: red;
  font-family: Gilroy-SemiBold;
  font-size: 13px;
  padding-top: 2px;
}
.sus-p {
  color: green;
  font-family: Gilroy-SemiBold;
  font-size: 17px;
  padding-top: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* multi select  */

.multi-select {
  width: 100%;
}
.multi-select h3 {
  font-family: Gilroy-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #616161;
  padding-bottom: 10px;
}

.multiple-select {
  /* margin: 20px 0; */
  position: relative;
}
.options-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #CEF1F3;
  color: #4F4F4F;
  font-family: Gilroy-SemiBold;
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.54px;
  width: 100%;
  border-radius: 8px;
}
.options-head h3 {
  font-family: Gilroy-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 8px;
  color: #4F4F4F;
  /* border: 1px solid red; */
}
.options-head h3 span {
  display: flex;
  color: #616161;
  gap: 20px;
}
.multiple-select_options {
  /* border: 1px solid red; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  background: #EBFEFF;
  box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 20;
}
.multiple-select_options p,
.multiple-select_options input {
  font-family: Gilroy-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0D2D53;
  padding: 8px 16px;
  width: 100%;
}
.multiple-select_options input{
    border: 1px solid #b8f3f6;
    border-radius: 8px;
}
.multiple-select_options p {
  margin: 4px 0;
}
.multiple-select_options h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #0D2D53;
  padding: 10px 16px;
  width: 100%;
  cursor: pointer;
}
.selected-css {
  border-radius: 8px;
  background: #cef1f3;
  color: #0D2D53;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multiple-select_options p span {
  display: flex;
  gap: 15px;
  align-items: center;
}
.display-none {
  display: none;
}

@media (max-width: 900px) {
  .input input,
  .textarea textarea,
  .input select,
  .input label {
    font-size: 14px;
  }
  .small-input input,
  .small-input select,
  .small-input label {
    font-size: 14px;
  }
}
