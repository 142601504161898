
.faq{
    width: 90%;
    margin: 0 auto;
    padding: 40px;
}
.faq-head{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
}
.faq-head_text{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.faq-head_text h2{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 120.93% */
    letter-spacing: -1.29px;
}
.faq-head_text p{
    color: #555;
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: -0.6px;
    width: 60%;
}

.faq-questions{
    padding: 40px 0 25px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.each-question{
    border-bottom: 1px solid #D4D4D4;
    padding: 10px 0;
}
.each-question h5{
    color: var(--new-theme-color, #1C2534);
    font-family: Gilroy-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.each-question p{
    color: #555;
    font-family: Gilroy-Medium;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 157.895% */
    letter-spacing: -0.57px;
    width: 60%;
    padding: 20px 0;
    cursor: pointer;
}

@media (max-width: 900px) {
    .faq{
        padding: 40px 0;
    }
    .faq-head{
        flex-direction: column-reverse;
    }
    .faq-head_img img{
        width: 100%;
    }
    .faq-head_text h2{
        font-size: 30px;
        width: 100%;
    }
    .faq-head_text p{
        font-size: 15px;
        width: 100%;
    }
    .each-question h5, .each-question p{
        font-size: 15px;
    }
    .each-question p{
        width: 100%;
    }
}