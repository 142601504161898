.gallery-head {
  border-radius: 38px;
  background: #defdff;
  border-top-right-radius: 0;
  padding: 60px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 40px;
}

.gallery-head_left {
  width: 55%;
}
.gallery-head_right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.gallery-head_right h2 {
  color: #000;
  text-align: right;
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 120.93% */
  letter-spacing: -1.29px;
}
.gallery-head_right p {
  color: #555;
  text-align: right;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: -0.6px;
}
.gallery-thumbnail {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
}
.g-thumb_head {
  display: flex;
  align-items: center;
}
.g-thumb_head p {
  color: #757575;
  font-family: Gilroy-Medium;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 140.909% */
  letter-spacing: -0.66px;
  padding: 0 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #d3d3d3;
  cursor: pointer;
}
.active-thumb {
  color: #07bef7 !important;
  border-bottom: 4px solid #07bef7 !important;
  font-weight: 600 !important;
}
.g-thumb_pic {
  /* width: 95%;
    margin: 0 auto; */
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  /* border: 2px solid red; */
}
.each-img img {
  width: 100%;
  height: 233px;
  cursor: pointer;
  border-radius: 8px;
}
.each-img {
  /* border: 2px solid red; */
  position: relative;
  width: 30%;
}
.vid-img {
  cursor: pointer;
}
.play-btn {
  /* border: 1px solid green; */
  position: absolute;
  top: 40%;
  left: 40%;
  cursor: pointer;
}
.play-btn img {
  width: 50px !important;
  height: 50px !important;
}
.overview {
  position: fixed;
  width: 100%;
  height: 150vh;
  background: #0D2D53;
  opacity: 0.7;
  color: white;
  z-index: 4;
  top: -20%;
  cursor: pointer;
}
.close-ov {
  padding-top: 13%;
  position: absolute;
  right: 5%;
  cursor: pointer;
}
.enlarged-modal {
  top: 6%;
  width: 70%;
  margin: 0 auto;
  position: fixed;
  z-index: 5;
}
.enlarged-modal img {
    width: 100%;
  height: 600px;
  border-radius: 38px;
}
.gal-slide_cont{
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
}
.gal-slide swiper-container {
  /* position: relative; */
  height: 390px;
  /* border: 1px solid red; */
}
.gal-slide swiper-container img{
  width: 100%;
  height: 350px;
  border-radius: 8px;
  cursor: pointer;
  /* border: 2px solid red; */
}
.gal-slide_btn{
  position: absolute;
  bottom: 0;
  right: 0;
}
.back-btn{
  padding-bottom: 20px;
}


@media (max-width: 900px) {
  .gallery-head {
    padding: 20px;
    flex-direction: column;
  }
  .gallery-head_right h2 {
    font-size: 30px;
  }
  .gallery-head_right p {
    font-size: 15px;
    text-align: left;
  }
  .gallery-head_right {
    align-items: flex-start;
  }
  .gallery-head_left,
  .gallery-head_right {
    width: 100%;
  }
  .g-thumb_head p {
    font-size: 16px;
  }
  .vid-img,
  .img-class {
    width: 328px;
  }
  .close-ov {
    top: 13%;
    position: absolute;
    right: 13%;
    cursor: pointer;
  }
  .enlarged-modal{
    width: 96%;
    top: 17%;
  }
  .enlarged-modal img{
    height: 450px;
  }
  .each-img{
    width: 100%;
  }
}
