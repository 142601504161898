.desktop-nav {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 20px;
  position: relative;
  justify-content: flex-end;
  /* border: 2px solid green; */
}
.nav-left {
  width: 35%;
  border-bottom-right-radius: 20px;
  /* border: 2px solid red; */
  padding: 30px 40px;
  position: absolute;
  left: 7px;
  bottom: -5px;
  background: white;
  /* top: 5px; */
}
.nav-right {
  /* border: 2px solid red; */
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 70px;
  background: #defdff;
  padding: 20px 40px;
  border-radius: 38px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* border-top-left-radius: 28px; */
}
.nav-links {
  display: flex;
  align-items: center;
  gap: 50px;
}
.nav-links ul {
  list-style: none;
}
.nav-links ul li {
  color: #484848;
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
}
.nav-links ul li span {
  height: 4px;
  background: var(--Theme, #07bef7);
  width: 40%;
}
.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  color: var(--Theme, #07bef7) !important;
  font-family: Gilroy-Bold !important;
}
.mobile-nav {
  display: none;
}

@media (max-width: 900px) {
  .nav-left img {
    width: 186px;
    height: 11px;
  }
  .nav-links {
    display: none;
  }
  .nav-btn {
    display: none;
  }
  .nav-left {
    padding: 25px;
    padding-left: 10px;
    /* border: 2px solid red; */
    width: 75%;
    left: 7px;
    bottom: -3px;
    border-bottom-right-radius: 15px;
  }
  .nav-right {
    width: auto;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 15px;
    padding-left: 40px;
  }
  .mobile-nav {
    display: flex;
  }
  .mobile-menu_close {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    padding: 40px;
    background: var(--Theme-2, #0d2d53);
  }
  .close-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-bottom: 2px solid white;
    padding-bottom: 20px;
  }
  .close-menu img {
    width: 204px;
    height: 12px;
  }
  .close-menu_menu {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 30px 0;
  }
  .close-menu_menu ul {
    list-style: none;
  }
  .close-menu_menu ul li {
    color: var(--Grey, #a0a0a0);
    font-family: Gilroy-SemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
  }
  .mobile-active {
    color: white !important;
    font-weight: 600 !important;
  }
  .close-menu_links{
    display: flex;
    align-items: center;
    gap: 25px;
    padding-left: 15px;
  }
  .close-menu_btn{
    padding: 30px 0 15px;
  }
}
