*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  max-width: 2000px;
  margin: 0 auto;
}

h1,h2,h3,h4,h5,h6{
  font-family: Gilroy-SemiBold;
}
p,button,input,textarea,select{
  font-family: Gilroy-Medium;
}
button,input,textarea,select{
  outline: none;
  border: none;
  background: transparent;
}
a{
  text-decoration: none;
}

.btn{
  border-radius: 5px;
  border: 1px solid var(--Theme-2, #0D2D53);
  display: flex;
  padding: 6px 22px;
  gap: 7px;
  align-items: center;
  justify-content: center;
  color: var(--Theme-2, #FFF);
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  letter-spacing: -0.48px;
  cursor: pointer;
  background: var(--Theme-2, #0D2D53);
}
.btn-not-allowed{
  border-radius: 5px;
  display: flex;
  padding: 6px 22px;
  gap: 7px;
  align-items: center;
  justify-content: center;
  color: var(--Theme-2, #FFF);
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  letter-spacing: -0.48px;
  cursor: not-allowed;
  background: var(--Theme-2, #D3D3D3);
}
.btn-white{
  border-radius: 5px;
  border: 1px solid var(--Theme-2, white);
  display: flex;
  padding: 6px 22px;
  gap: 7px;
  align-items: center;
  justify-content: center;
  color: var(--Theme-2, #0D2D53);
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  letter-spacing: -0.48px;
  cursor: pointer;
  background: var(--Theme-2, white);
}
.btn-blue{
  border-radius: 5px;
  border: 1px solid var(--Theme-2, #07BEF7);
  display: flex;
  padding: 6px 22px;
  align-items: center;
  gap: 6px;
  color: var(--Theme-2, #FFF);
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  letter-spacing: -0.48px;
  cursor: pointer;
  background: var(--Theme, #07BEF7);
}
.btn-trans{
  border-radius: 5px;
  border: 1px solid var(--Theme-2, #0D2D53);
  display: flex;
  padding: 6px 22px;
  align-items: center;
  gap: 6px;
  color: var(--Theme-2, #0D2D53);
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 225% */
  letter-spacing: -0.48px;
  cursor: pointer;
}
.btn img, .btn-blue img, .btn-trans img, .btn-white img{
  width: 40px;
  height: 40px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 0.5px solid transparent; /* Create space around the scrollbar thumb */
  background-clip: padding-box; /* Ensure the border is visible */
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media (max-width: 900px) {
  .btn{
    color: var(--White, #FFF);
    font-family: Gilroy-SemiBold;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 240% */
    letter-spacing: -0.45px;
  }
}