
.core-values{
    width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
    padding: 60px 40px;
    justify-content: space-between;
}
.core-values_left, .core-values_right{
    width: 45%;
}
.core-values_left{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.core-values_left h3{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 111.628% */
    letter-spacing: -1.29px;
}
.core-values_cont{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.each-core_value{
    display: flex;
    gap: 25px;
}
.each-core_value h4{
    color: var(--Theme, #07BEF7);
    font-family: Gilroy-Bold;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 184.615% */
    letter-spacing: -0.78px;
}
.each-core_body h5{
    color: #0D2D53;
    font-family: Gilroy-Bold;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px; /* 147.619% */
    letter-spacing: -0.63px;
}
.each-core_body p{
    color: #454545;
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: -0.6px;
}
.core-values_right{
    display: flex;
    flex-direction: column;
    gap: 80px;
}
.core-mission{
    display: flex;
    flex-direction: column;
    gap: 35px;
}
.core-mission h3{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 111.628% */
    letter-spacing: -1.29px;
}
.core-mission p{
    color: #555;
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: -0.6px;
}

@media (max-width: 900px) {
    .core-values{
        flex-direction: column;
        padding: 40px 0;
    }
    .core-values_left, .core-values_right{
        width: 100%;
    }
    .each-core_body h5, .each-core_body p{
        font-size: 15px;
    }
    .core-mission h3, .core-values_left h3{
        font-size: 30px;
    }
    .core-mission p{
        font-size: 15px;
    }
}