.hero {
  width: 90%;
  margin: 0 auto;
  background: #defdff;
  border-radius: 38px;
  border-top-right-radius: 0;
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 40px;
}

.hero-left {
  width: 43%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
}
.hero-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.hero-text h1 {
  color: var(--Theme-2, #0d2d53);
  font-family: Gilroy-SemiBold;
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 117.073% */
  letter-spacing: -1.23px;
  width: 80%;
}
.hero-text h1 span {
  color: var(--Theme, #07bef7);
}
.span-line {
  background: var(--Theme, #07bef7);
  border-radius: 50px;
  width: 70px;
  height: 4px;
}
.youth-p {
  color: var(--Theme-2, #0d2d53);
  font-family: Gilroy-Bold;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 61.905% */
  letter-spacing: -1.26px;
}
.youth-p span {
  color: #323232;
  font-family: Gilroy-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  letter-spacing: -0.6px;
}
.power-tehub {
  position: absolute;
  left: 60%;
  bottom: 20%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.power-tehub p {
  color: #000;
  font-family: Gilroy-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 216.667% */
  letter-spacing: -0.36px;
}
.button-icon {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 20px;
}
.hero-icons {
  display: flex;
  gap: 10px;
  align-items: center;
}
.hero-right {
  width: 55%;
}
.hero-right img {
  width: 100%;
}

@media (max-width: 900px) {
  .hero {
    flex-direction: column-reverse;
    padding: 15px;
    border-radius: 15px;
    border-top-right-radius: 0;
  }
  .hero-left {
    width: 100%;
  }
  .hero-text h1 {
    font-size: 30px;
    width: 100%;
  }
  .youth-p {
    font-size: 30px;
    width: 55%;
  }
  .youth-p span {
    color: #323232;
    font-family: Gilroy-SemiBold;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.45px;
    font-weight: 600;
  }
  .power-tehub{
    left: 65%;
    bottom: 25%;
  }
  .power-tehub p {
    font-size: 12px;
  }
  .power-tehub img {
    width: 89px;
    height: 29px;
  }
  .hero-right {
    width: 100%;
  }
  .button-icon {
    padding: 0;
  }
}
