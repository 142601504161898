.training-parts {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.t-header {
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.t-header h2 {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 60.465% */
  letter-spacing: -1.29px;
}
.t-header p {
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 135% */
  letter-spacing: -0.6px;
  width: 50%;
}
.t-logo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
  /* padding: 10px 50px; */
}
.t-partner {
  border-radius: 10px;
  background: var(--Theme-2, #0d2d53);
  padding: 34px 137px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.t-partner h3 {
  color: var(--white, #fff);
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 120.93% */
  letter-spacing: -1.29px;
}
.t-partner p {
  color: #ddecff;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 133.333% */
  letter-spacing: -0.63px;
  width: 75%;
}
.upcoming-t {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid red; */
  padding: 90px 30px 20px 0;
}
.upcoming-t_head h2 {
  color: var(--Theme-2, #0d2d53);
  font-family: Gilroy-Bold;
  font-size: 43px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 72.093% */
  letter-spacing: -1.29px;
  padding-bottom: 20px;
}
.upcoming-t_head h6 {
  width: 428px;
  height: 4px;
  border-radius: 50px;
  background: var(--Theme, #07bef7);
}
.upcoming-t p {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: flex-end;
  color: var(--Theme, #07bef7);
  font-family: Gilroy-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 108.333% */
  letter-spacing: -0.72px;
  padding-bottom: 20px;
  cursor: pointer;
}
.training-partners{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.each-partner{
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.each-partner img{
  width: 100px;
  height: 50px;
}

swiper-container::part(bullet-active) {
  background-color: #07bef7;
  width: 66px;
  height: 9px;
  border-radius: 8px;
}
.slider-img swiper-container {
  /* position: relative; */
  height: 450px;
}
swiper-container::part(bullet) {
  width: 16px;
  height: 9px;
  background-color: #badee0;
  border-radius: 8px;
  opacity: 1;
}
swiper-container::part(pagination) {
  /* border: 2px solid green; */
  display: flex;
  justify-content: flex-start;
  padding-left: 60px;
}
.slider-img swiper-container img {
  width: 100%;
  height: 403px;
  border-radius: 15px;
}

@media (max-width: 900px) {
  swiper-container {
    /* position: relative; */
    height: 190px;
  }
  swiper-container img {
    /* border: 3px solid red; */
    width: 290px;
    height: 157px;
    border-radius: 10px;
  }
  swiper-container::part(pagination) {
    /* border: 2px solid green; */
    padding-left: 0;
  }
  swiper-container::part(bullet-active) {
    height: 3.687px;
  }
  swiper-container::part(bullet) {
    height: 3.687px;
  }
  .training-parts {
    width: 100%;
    padding: 20px;
  }
  .t-header {
    padding: 10px;
  }
  .t-header h2 {
    font-size: 30px;
  }
  .t-header p {
    font-size: 15px;
    width: 100%;
  }
  .each-partner{
    padding-left: 10px;
  }
  .t-logo img:first-child {
    width: 32.325px;
    height: 25.171px;
  }
  .t-logo img:nth-child(2) {
    width: 54.581px;
    height: 25.171px;
  }
  .t-logo img:nth-child(3) {
    width: 61.205px;
    height: 25.171px;
  }
  .t-logo img:nth-child(4) {
    width: 61.735px;
    height: 28.35px;
  }
  .t-logo img:nth-child(5) {
    width: 20.137px;
    height: 25.171px;
  }
  .t-logo img:nth-child(6) {
    width: 27.119px;
    height: 25px;
  }
  .t-logo {
    gap: 20px;
    padding: 0 10px;
  }
  .t-partner {
    padding: 20px;
    gap: 20px;
  }
  .t-partner h3 {
    font-size: 30px;
  }
  .t-partner p {
    font-size: 15px;
    width: 100%;
  }
  .upcoming-t_head h2 {
    font-size: 30px;
    padding-top: 20px;
  }
  .upcoming-t_head h6 {
    width: 208px;
  }
}
