.footer {
  background: var(--Theme-2, #0d2d53);
  display: flex;
  padding: 42px 71px 28px 114px;
  gap: 33px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-left {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* border: 1px solid red; */
}
.footer-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 25%;
  /* border: 1px solid red; */
}
.footer-left img {
  width: 280px;
  height: 16px;
}
.footer-left h1 {
  color: var(--white, #fff);
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 111.628% */
  letter-spacing: -1.29px;
  width: 75%;
}
.footer-left p {
  color: #fff;
  font-family: Gilroy-SemiBold;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 21px */
  letter-spacing: -0.45px;
}
.footer-right h4 {
  color: #fff;
  font-family: Gilroy-SemiBold;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.63px;
}
.footer-right h5 {
  color: #fff;
  font-family: Gilroy-SemiBold;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.45px;
  width: 70%;
}
.footer-logos{
    display: flex;
    align-items: center;
    gap: 10px;
}
.mobile-p{
    display: none;
}

@media (max-width: 900px) {
    .footer{
        flex-direction: column;
        padding: 40px 20px;
        gap: 50px;
    }
    .footer-left, .footer-right{
        width: 100%;
    }
    .footer-left p{
        display: none;
    }
    .footer-left h1{
        width: 100%;
        font-size: 30px;
    }
    .footer-right h5{
        width: 100%;
    }
    .mobile-p{
        display: block;
        color: #fff;
        font-family: Gilroy-SemiBold;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 21px */
        letter-spacing: -0.45px;
    }
}

