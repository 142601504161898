
.contactus{
    width: 90%;
    margin: 0 auto;
}
.contactus-head{
    background: #defdff;
    border-radius: 38px;
    border-top-right-radius: 0;
    padding: 39px;
}
.contactus-head h2{
    color: #000;
    text-align: right;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 120.93% */
    letter-spacing: -1.29px;
}
.contact-div{
    width: 95%;
    margin-left: auto;
    padding: 40px 0 60px;
    display: flex;
    align-items: center;
    gap: 70px;
}
.contact-left{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 46%;
}
.contact-left h3{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 37px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 140.541% */
    letter-spacing: -1.11px;
}
.contact-left_details{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.contact-left_details p{
    display: flex;
    align-items: center;
    gap: 10px;
    color: #373636;
    font-family: Gilroy-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px; /* 125% */
    letter-spacing: -0.6px;
}
.contact-right{
    border-radius: 15px;
    background: #EBFEFF;
    display: inline-flex;
    padding: 52px 45px;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
    width: 52%;
}
.contact-right_btn{
    width: 100%;
    /* border: 1px solid red; */
}

@media (max-width: 900px) {
    .contact-div{
        flex-direction: column;
        gap: 20px;
    }
    .contact-left, .contact-right{
        width: 100%;
    }
    .contact-left h3{
        font-size: 22px;
    }
    .contactus-head h2{
        font-size: 30px;
    }
    .contact-left_details p{
        font-size: 15px;
    }
    .contact-left{
        position: relative;
        width: 90%;
    }
    .contact-left_map img{
        position: absolute;
        bottom: 5px;
        width: 100%;
        right: 10px;
    }
    .contact-right{
        padding: 16px;
    }
    .contact-div{
        width: 100%;
    }
}