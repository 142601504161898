

.team{
    width: 90%;
    margin: 0 auto;
    padding: 40px;
}
.team h2{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 60.465% */
    letter-spacing: -1.29px;
    padding-bottom: 20px;
} 

.team-group{
    display: flex;
    gap: 35px;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
    /* justify-content: center; */
}
.each-team{
    width: 22%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.each-team img{
    width: 100%;
    height: 250px;
}
.each-team h3{
    color: #000;
    text-align: center;
    font-family: Gilroy-Bold;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 100% */
    letter-spacing: -0.78px;
}
.each-team p{
    color: #858585;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
    letter-spacing: -0.6px;
}
.team-links{
    display: flex;
    align-items: center;
    gap: 8px;
    /* border: 2px solid red; */
    justify-content: center;
}
.team-links img{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

@media (max-width: 900px) {
    .team{
        padding: 20px 0;
    }
    .team h2{
        font-size: 30px;
        padding-bottom: 10px;
    }
    .team-img{
        height: 170px !important;
    }
    .team-group{
        gap: 16px;
    }
    .each-team{
        width: 47%;
    }
    .each-team h3{
        font-size: 18px;
    }
    .each-team p{
        font-size: 15px;
    }
    .team-links{
        gap: 4px;
    }
}