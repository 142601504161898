@font-face {
  font-family: Gilroy-SemiBold;
  src: url("./assets/fonts/gilroy-bold-cufonfonts-webfont/Gilroy-Medium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Gilroy-Medium;
  src: url("./assets/fonts/gilroy-bold-cufonfonts-webfont/Gilroy-Light.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Gilroy-Regular;
  src: url("./assets/fonts/gilroy-bold-cufonfonts-webfont/Gilroy-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Gilroy-Bold;
  src: url("./assets/fonts/gilroy-bold-cufonfonts-webfont/Gilroy-Bold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
