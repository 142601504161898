
.align-un{
    width: 90%;
    margin: 0 auto;
    border-radius: 69px;
    background: #DEFDFF;
    padding: 52px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    position: relative;
}
.left-top{
    height: 20%;
    background-color: white;
    position: absolute;
    top: -50px;
    left: 0;
    width: 45%;
    border-radius: 38px;
    border-bottom-left-radius: 0;
}
.align-left{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.left-bottom{
    border-radius: 38px;
    border: 4px dashed var(--Theme-2, #0D2D53);
    padding: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
}
.align-right{
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 45%;
}
.align-right h3{
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 43px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 111.628% */
    letter-spacing: -1.29px;
}
.align-right h3 span{
    color: #07BEF7;
}
.align-right_goals{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.each-goal{
    display: flex;
    gap: 22px;
}
.each-goal p{
    color: #07BEF7;
    font-family: Gilroy-Bold;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 147.619% */
    letter-spacing: -0.63px;
    /* display: flex;
    gap: 5px; */
}
.each-goal p span{
    color: #3C3C3C;
}
.each-goal img{
    width: 20px;
    height: 20px;
    margin-top: 8px;
}

@media (max-width: 900px) {
    .left-top{
        display: none;
    }
    .align-un{
        flex-direction: column;
        padding: 10px 10px 20px;
        border-radius: 30px;
    }
    .align-left, .align-right{
        width: 100%;
    }
    .left-bottom{
        margin-top: 20px;
    }
    .left-bottom img{
        width: 100%;
    }
    .align-right h3{
        font-size: 30px;
    }
    .each-goal p{
        font-size: 15px;
    }
}