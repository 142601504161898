.training {
  width: 90%;
  margin: 0 auto;
}
.training-head {
  background: #defdff;
  border-radius: 38px;
  border-top-right-radius: 0;
  padding: 39px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.training-head_left {
  width: 35%;
  padding-top: 60px;
}
.training-head_right {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.each-list p {
  color: #757575;
  border-left: 4px solid #d9d9d9;
  font-family: Gilroy-Medium;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 124% */
  letter-spacing: -0.75px;
  padding-left: 50px;
  padding-bottom: 20px;
  cursor: pointer;
}
.active-list {
  color: #07bef7 !important;
  border-left: 4px solid #07bef7 !important;
  font-weight: 600 !important;
}
.training-head_right h3 {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 120.93% */
  letter-spacing: -1.29px;
}
.training-head_right h4 {
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: -0.6px;
  width: 90%;
}
.tr-active_menu {
  display: flex;
  /* gap: 40px; */
}
.tr-active_menu p {
  color: #757575;
  font-family: Gilroy-Medium;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 140.909% */
  letter-spacing: -0.66px;
  border-bottom: 2px solid #d3d3d3;
  padding: 0 30px;
  padding-bottom: 25px;
  cursor: pointer;
}
.active-tr {
  color: #07bef7 !important;
  border-bottom: 4px solid #07bef7 !important;
  font-weight: 600 !important;
}
.training-body {
  padding: 40px 0;
}
.training-body h3 {
  color: var(--Theme-2, #0d2d53);
  font-family: Gilroy-SemiBold;
  font-size: 43px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 120.93% */
  letter-spacing: -1.29px;
  padding: 20px 0;
}
.training-body_cont{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.t-partners img{
  width: 50px;
  height: 50px;
}

/* active training component  */
.active-training {
  border-radius: 38px;
  background: #defdff;
  padding: 60px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  /* border: 1px solid red; */
}
.active-training_left {
  width: 35%;
}
.active-training_left img{
  width: 100%;
  height: 387px;
  border-radius: 10px;
}
.active-training_left h3 {
  color: #494949;
  font-family: Gilroy-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px; /* 155% */
  letter-spacing: -0.6px;
  padding-left: 20px;
}
.ti-div {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 20px;
}
.ti-div img{
  width: 49px;
  height: 49px;
  border-radius: 50%;
}
.ti-div p {
  color: #494949;
  font-family: Gilroy-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 140.909% */
  letter-spacing: -0.66px;
}
.active-training_left h4 {
  border: 1px solid #bedddf;
  margin: 20px 0;
}
.users-div {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 50px;
}
.users-div_img {
  display: flex;
  margin-left: -30px;
}
.users-div_img img {
  width: 39px;
  height: 42px;
  border-radius: 50%;
}
.active-training_left h5 {
  color: #494949;
  font-family: Gilroy-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.66px;
  padding: 20px 0;
  padding-left: 20px;
}
.app-stats {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
}
.app-stats p {
  color: #494949;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 155% */
  letter-spacing: -0.6px;
}
.app-stats h6 {
  /* color: var(--Theme, #07BEF7); */
  font-family: Gilroy-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 129.167% */
  letter-spacing: -0.72px;
}

.active-training_right {
  width: 55%;
}
.at-right_top,
.at-right_bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.at-right_top h2 {
  color: #000;
  font-family: Gilroy-SemiBold;
  font-size: 37px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px; /* 127.027% */
  letter-spacing: -1.11px;
}
.at-date_div,
.at-add_div {
  display: flex;
  align-items: center;
  gap: 25px;
}
.icon-at {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--Theme, #07bef7);
  color: white;
  padding: 13px 12px;
}
.at-date_div p,
.at-add_div p {
  color: #494949;
  font-family: Gilroy-SemiBold;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 134.783% */
  letter-spacing: -0.69px;
}
.at-date_div p span,
.at-add_div p span {
  color: #8fb3be;
}
.at-btn {
  width: 100%;
  /* border: 1px solid red; */
}
.at-right_about h4,
.at-criteria h4 {
  color: #202020;
  font-family: Gilroy-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px; /* 140.909% */
  letter-spacing: -0.66px;
}
.at-right_bottom {
  padding-top: 40px;
}
.at-right_bottom p {
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
  letter-spacing: -0.6px;
  padding: 5px 0;
}
.at-right_about ul,
.at-criteria ul {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.at-right_about ul li,
.at-criteria ul li {
  color: #555;
  font-family: Gilroy-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
  letter-spacing: -0.6px;
  padding: 5px 0;
}


/* training form  */
.form-overlay{
  position: fixed;
  width: 100%;
  height: 150vh;  
  background: rgba(13, 45, 83, 0.7);
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px);
  color: white;
  z-index: 4;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
}
.close-form{
  position: absolute;
  right: 80px;
  top: 20px;
}
.application-form{
  height: 90vh;
  width: 50%;
  margin: 0 auto;
  border-radius: 15px;
  background: #EBFEFF;
  position: absolute;
  top: 30px;
  left: 25%;
  overflow-y: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 70px;
}
.application-form h2{
  /* border: 2px solid red; */
  color: #0D2D53;
}
.app-submit{
  padding: 20px 0;
}
.app-btn{
  width: 100% !important;
}


@media (max-width: 900px) {
  .training-head {
    flex-direction: column-reverse;
    padding: 20px;
  }
  .training-head_left,
  .training-head_right {
    width: 100%;
  }
  .each-list p {
    font-size: 18px;
  }
  .training-head_right h3 {
    font-size: 30px;
  }
  .training-head_right h4 {
    font-size: 15px;
  }
  .tr-active_menu p {
    font-size: 15px;
    padding: 0 20px;
  }
  .training-body h3 {
    font-size: 30px;
  }

  .active-training {
    flex-direction: column;
    padding: 16px;
  }
  .active-training_left,
  .active-training_right {
    width: 100%;
  }
  .at-right_top h2 {
    font-size: 22px;
  }
  .at-date_div p,
  .at-add_div p {
    font-size: 15px;
  }
  .training-img {
    width: 100%;
  }
  .active-training_left h3,
  .at-right_about ul li,
  .at-criteria ul li,
  .at-right_bottom p {
    font-size: 15px;
  }
  .ti-div p,
  .active-training_left h5,
  .app-stats h6 {
    font-size: 17px;
  }
  .training-img{
    height: 287px !important;
  }
  .application-form{
    width: 95%;
    left: 2.5%;
    padding: 15px;
    top: 40px;
  }
  .close-form{
    top: 5px;
    right: 20px;
  }
}
