
.pag{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.pag-prev, .pag-next{
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid var(--Theme-2, #0D2D53);
    padding: 6px 15px;
    cursor: pointer;
}

.disabled-pag{
    opacity: 0.2 !important;
    cursor: not-allowed !important;
}